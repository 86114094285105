function Header(props) {
    const changeDataset = (name) => {
        props.changeDataset(name)
    }
    const onChangeShuffleSelect = (val) => {
        props.setShuffle(val.target.value)
    }
    return (
        <div className="question bg-white p-3 border-bottom">
            <div className="d-flex flex-row justify-content-between align-items-center mcq">
                <h4>Entrance Quiz</h4>
                <button className="btn btn-primary d-flex align-items-center btn-success" type="button"
                        onClick={() => changeDataset("pitvy")}
                        disabled={"pitvy" === props.currentDataset}>
                    Pitvy
                </button>
                {/*<button className="btn btn-primary d-flex align-items-center btn-success" type="button"*/}
                {/*        onClick={() => changeDataset("bio")}*/}
                {/*        disabled={"bio" === props.currentDataset}>*/}
                {/*    Bio*/}
                {/*</button>*/}
                {/*<button className="btn btn-primary d-flex align-items-center btn-success" type="button"*/}
                {/*        onClick={() => changeDataset("chemia")}*/}
                {/*        disabled={"chemia" === props.currentDataset}*/}
                {/*    >*/}
                {/*    Chemia*/}
                {/*</button>*/}
                <select value={props.shuffle} onChange={onChangeShuffleSelect} className="custom-select w-25">
                    <option value={false}>Normal order</option>
                    <option value={true}>Shuffle order</option>
                </select>
            </div>
        </div>
    )
}

export default Header;