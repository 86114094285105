function Question(props) {
    const question_text = props.question.question;
    return (
        <div className="d-flex flex-row align-items-center question-title">
            <h3 className="text-danger">Q.</h3>
            <h5 className="mt-1 ml-2">{question_text}</h5>
        </div>
    )
}

export default Question;