import React from "react";
import ShowStatus from "./ShowStatus";

function Answer(props) {
    const idx = props.idx;
    const {data, status, checked} = props;
    const handleChange = () => {
        if(status !== undefined) return;
        props.changeAnswer(idx, !checked)
    }
    const spanStyle = {
        "textTransform": 'none',
    };
    return (
        <div className="ans ml-2">
            <label className="radio">
                <input type="checkbox" checked={checked} name="brazil" value="brazil"
                       onChange={handleChange}/>
                <span style={spanStyle}>{data}</span>
                {status === false || status === true ? <ShowStatus status={status}/> : ""}
            </label>
        </div>
    )
}

export default Answer;