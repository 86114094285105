import './App.css';
import Quiz from "./components/Quiz";

function App() {
  return (
    <div className="App">
      <Quiz/>
    </div>
  );
}

export default App;
