import Answer from "./Answer";
import React from "react";

function Options(props) {
    const answers = props.question.ans || [];
    return (
        <>
            {answers.map((x, idx) =>
                <Answer
                    key={idx}
                    data={x}
                    idx={idx}
                    changeAnswer={props.changeAnswer}
                    status={props.statusAns[idx]}
                    checked={props.checked[idx]}
                />)
            }
        </>
    )
}

export default Options