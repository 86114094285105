import React, {useState} from "react";

function SubmitButton(props) {
    const [gotoInput, setGotoInput] = useState('');
    const checked = props.checked;
    const correct = props.correct;

    const handleReset = () => {
        props.resetQ();
    }
    const handleRandom = () => {
        props.randomQ();
    }
    const handleNext = () => {
        props.nextQ();
    }
    const handleGoTo = () => {
        if(gotoInput === "") return;
        console.log("goto dbg ", gotoInput)
        const gotoInputInt = parseInt(gotoInput);
        props.gotoQ(gotoInputInt-1);
    }
    const handleSubmit = () => {
        console.log("tttt", checked, correct);
        const out = checked.map((val, idx) => {
            if(val){
                return correct.includes(idx);
            }
            return !correct.includes(idx);
        })
        console.log("ttt2", out);
        props.setStatusAns(out)
    }
    return (
        <>
            <button className="btn btn-primary d-flex align-items-center btn-success" type="button" onClick={handleReset}>
                Reset
            </button>
            <button className="btn btn-primary d-flex align-items-center btn-success" type="button" onClick={handleSubmit}>
                Submit
            </button>
            <button className="btn btn-primary border-success align-items-center btn-success"
                    type="button" onClick={handleNext}>
                Next
            </button>
            <button className="btn btn-primary border-success align-items-center btn-success"
                    type="button" onClick={handleRandom}>
                Random
            </button>
            <button className="btn btn-primary border-success align-items-center btn-success"
                    type="button" onClick={handleGoTo}>
                Go to
            </button>
            <input type="text" className="form-control w-25" value={gotoInput} onInput={e => setGotoInput(e.target.value)}/>
        </>
    )
}

export default SubmitButton;
