import QuizContent from "./QuizContent";

function Quiz() {
    return (
        <div className="container mt-5">
            <div className="d-flex justify-content-center row">
                <div className="col-md-10 col-lg-10">
                    <div className="border">
                        <QuizContent/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quiz;