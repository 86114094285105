import React, {useEffect, useState} from "react";
import Header from "./Header";
import Question from "./Question";
import Options from "./Options";
import SubmitButton from "./SubmitButton";
import {getDataset} from "../services/dataset";

function QuizContent() {
    const defaultChecked = new Array(5).fill(false);
    const [nameDataset, setNameDataset] = useState("biochem");
    const [questions, setQuestions] = useState([]);
    const [current, setCurrent] = useState({});
    const [checked, setChecked] = useState(defaultChecked);
    const [statusAns, setStatusAns] = useState([]);
    const [shuffle, setShuffle] = useState("false");

    const rand = (max) => {
        return Math.floor(Math.random() * max);
    }

    const randomQ = () => {
        const rand_idx = rand(questions.length);
        changeQ(rand_idx);
    }

    const nextQ = () => {
        const idx = (current.idx + 1) % questions.length;
        changeQ(idx);
    }

    const removeStart = (q) => {
        q.ans = q.ans.map(val => {
            return val.slice(3, val.length);
        })
        return q;
    }

    const shuffleOptions = (q) => {
        let new_q = JSON.parse(JSON.stringify(q));
        let currentIndex = new_q.ans.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = new_q.ans[currentIndex];
            new_q.ans[currentIndex] = new_q.ans[randomIndex];
            new_q.ans[randomIndex] = temporaryValue;
            // change correct
            new_q.correct = new_q.correct.map(val => {
                if(val === randomIndex) return currentIndex;
                if(val === currentIndex) return randomIndex;
                return val;
            })
        }
        new_q = removeStart(new_q);
        return new_q;
    }

    const changeQ = (idx) => {
        if(idx >= questions.length || idx < 0) return;
        let q = questions[idx];
        if (shuffle === "true"){
            q = shuffleOptions(q);
        }
        setCurrent(q);
        setChecked(defaultChecked)
        setStatusAns([]);
    }

    const changeAnswer = (idx, status) => {
        const out = checked.slice();
        out[idx] = status;
        setChecked(out);
    }

    const changeDataset = (name) => {
        setNameDataset(name)
        const dataset = getDataset(name);
        const qAll = dataset.qAll;
        setQuestions(qAll);
    }

    useEffect(() => {
        changeDataset(nameDataset)
    }, [])

    useEffect(() => {
        randomQ()
    }, [questions])
    return (
        <>
            <Header
                changeDataset={changeDataset}
                currentDataset={nameDataset}
                shuffle={shuffle}
                setShuffle={setShuffle}
            />
            <div className="question bg-white p-3 border-bottom">
                <Question question={current}/>
                <Options
                    question={current}
                    changeAnswer={changeAnswer}
                    statusAns={statusAns}
                    checked={checked}/>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center p-3 bg-white">
                <SubmitButton
                    nextQ={nextQ}
                    randomQ={randomQ}
                    gotoQ={changeQ}
                    resetQ={() => changeQ(current.idx)}
                    setStatusAns={setStatusAns}
                    checked={checked}
                    correct={current.correct}/>
            </div>
        </>
    )
}

export default QuizContent