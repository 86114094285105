import questions_bio from "../data/entrance/bio/questions_bio";
import questions_bio_ans from "../data/entrance/bio/questions_bio_ans";
import questions_chemia from "../data/entrance/chemia/questions_chemia";
import questions_chemia_ans from "../data/entrance/chemia/questions_chemia_ans";
import questions_pitvy from "../data/pitvy/questions_pitvy";
import questions_pitvy_ans from "../data/pitvy/questions_pitvy_ans";
import questions_biochem from "../data/biochem/questions";

const getText = (name) => {
    return {questions: questions_biochem}
    // if(name === "bio"){
    //     return {questions: questions_bio, ans: questions_bio_ans}
    // } else if (name === "chemia"){
    //     return {questions: questions_chemia, ans: questions_chemia_ans}
    // } else if (name === "pitvy"){
    //     return {questions: questions_pitvy, ans: questions_pitvy_ans}
    // }
}

const getDataset = (name) => {
    const {questions, ans} = getText(name);
    const lines = questions.split('\n');
    // const lines_ans = ans.split('\n');
    const questionsRaw = [];
    const chunk = 6;
    let j = 0;
    for (let i = 0; i < lines.length; i += chunk) {
        const temparray = lines.slice(i, i + chunk - 1);
        console.log("dbg1:", lines[i])
        console.log("dbg2:", lines[i+5])
        questionsRaw.push({q: temparray, ans: lines[i+5]})
        j++;
    }
    const qAll = questionsRaw.map((x, idx) => parseQuestion(x, idx));
    return {qAll, numberOfOptions: 4};
}

const parseQuestion = (obj, idx) => {
    const q = obj.q;
    let ans = obj.ans;
    const out = {};
    out.idx = idx;
    out.question = q[0];
    out.ans = q.slice(1, q.length);
    // ans = ans.split(".")[1].trim();
    // const ans_arr = [...ans];
    out.correct = []
    if(ans.includes("A")) out.correct.push(0)
    if(ans.includes("B")) out.correct.push(1)
    if(ans.includes("C")) out.correct.push(2)
    if(ans.includes("D")) out.correct.push(3)
    // out.correct = ans_arr.map((val, idx) => {
    //     if(val === "S") return idx;
    //     return -1
    // }).filter(val => {
    //     return val !== -1;
    // })
    return out;
}

export {getDataset};