import {InlineIcon} from "@iconify/react";
import checkmarkIcon from "@iconify-icons/gridicons/checkmark";
import crossSmall from "@iconify-icons/gridicons/cross-small";
import React from "react";

function ShowStatus(props) {
    return (
        <>
            <div className="showStatus">
                {props.status ?
                    <InlineIcon icon={checkmarkIcon} color="green" /> :
                    <InlineIcon icon={crossSmall} color="red"/>
                }
            </div>
        </>
    )
}

export default ShowStatus